import React from "react"

import FrontPage from "../components/FrontPage"
import Layout from "../components/Layout"
import Page from "../components/Page"
import SEO from "../components/SEO"
import HeroImage from "../assets/images/hero.jpg"
import Metals from "../assets/images/scrap.jpg"
import Pole from "../assets/images/pole.jpg"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Millennium Metals" />
      <FrontPage
        id="home"
        heading="Committed to providing quality recyclables"
        paragraph="We are a ferrous and non-ferrous metals processor located in the Point Lisas industrial estate with a feeder yard located in Mausica, Arima. We take raw scrap materials and manufacture them into marketable products for global manufacturing industries."
        image={HeroImage}
      />
      <Page
        id="about"
        heading="We provide quality materials to both local and overseas industries"
        paragraph="We are readily available to acquire scrap material from individuals and companies. We are especially interested in purchasing scrap materials including copper, stainless steel, brass, aluminium, iron and all components which contain these metals."
        image={Metals}
        opacity={0}
      />
      <Page
        id="services"
        heading="our operation"
        paragraph="The operation involves the collection, identification, processing and marketing of ferrous and non-ferrous metals as well as other recyclable materials. Our main focus is the transformation of industrial scrap metals into reusable raw material which is then distributed to customers worldwide."
        image={Pole}
        opacity={0}
        reverse
      />
      <Page
        id="contact"
        heading="contact us"
        paragraph="Our office hours are:
        Monday to Friday – 8 AM to 5 PM. Saturday – 8 AM – 12 PM
        67-69 Southern Main Road,
        Savonetta, Point Lisas.
        Tel. 636-8209/9314 Fax. 636-9238"
        opacity={0}
        image={HeroImage}
      />
    </Layout>
  )
}

export default IndexPage
