import React from "react"
import FullHeight from "react-full-height"
import LazyHero from "react-lazy-hero"
import { Flex, Heading, Text } from "rebass"
import styled from "styled-components"

import Logo from "./Logo"

const FullHeightSection = styled(FullHeight)`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 100vh;
    justify-content: center;
  }
`

const StyledLazyHero = styled(LazyHero)`
  @media (max-width: 768px) {
    display: none;
  }
`

const CenteredSlide = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    padding: 16px 0;

    > div {
      width: 100%;
      padding: 16px;
      border: none;
      align-items: center;
    }
  }
`

const StyledHeading = styled(Heading)`
  font-family: "abeatbykai" !important;
  font-weight: normal;
  text-transform: lowercase;
  text-decoration: none;
  transition: all 200ms linear;
  color: #363636;
`

const FrontPage = ({ id, heading, paragraph, image }) => {
  return (
    <FullHeightSection id={id}>
      <CenteredSlide style={{ borderRight: "1px solid #eee" }}>
        <Flex width="50%" justifyContent="center">
          <div>
            <Logo />
            <Heading
              color="#2F8996"
              fontSize={48}
              fontFamily="abeatbykai"
              my={3}
              sx={{
                textTransform: "lowercase",
                fontStyle: "normal",
                fontWeight: 500,
                letterSpacing: "1.3px",
                lineHeight: 1.125,
              }}
            >
              {heading}
            </Heading>
            <Text color="#363636" lineHeight="1.5">
              {paragraph}
            </Text>
          </div>
        </Flex>
      </CenteredSlide>
      <StyledLazyHero
        imageSrc={image}
        minHeight="100vh"
        isFixed
        style={{ width: "50%", borderLeft: "1px solid #eee" }}
      >
        <StyledHeading>
          We are leaders in providing quality materials.
        </StyledHeading>
      </StyledLazyHero>
    </FullHeightSection>
  )
}

export default FrontPage
