import React from "react"
import FullHeight from "react-full-height"
import LazyHero from "react-lazy-hero"
import { Flex, Heading, Text } from "rebass"
import styled from "styled-components"

const FullHeightSection = styled(FullHeight)`
  display: flex;
  align-items: center;
`
const CenteredSlide = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;

    > div {
      width: 100%;
      padding: 16px;
      border: none;
      max-height: 50vh !important;
    }
  }
`

const StyledLazyHero = styled(LazyHero)`
  @media (max-width: 768px) {
    display: none;
  }
`

const StyledHeading = styled(Heading)`
  font-family: "abeatbykai" !important;
  font-weight: normal;
  text-transform: lowercase;
  text-decoration: none;
  transition: all 200ms linear;
  color: #363636;
`

const Page = ({ id, heading, paragraph, image, opacity, reverse }) => {
  if (reverse) {
    return (
      <FullHeightSection id={id}>
        <CenteredSlide style={{ borderRight: "1px solid #eee" }}>
          <Flex width="50%" justifyContent="center">
            <div>
              <StyledHeading
                color="#2F8996"
                fontSize={48}
                fontFamily="abeatbykai"
                my={3}
                sx={{
                  textTransform: "lowercase",
                  fontStyle: "normal",
                  fontWeight: 500,
                  letterSpacing: "1.3px",
                  lineHeight: 1.125,
                }}
              >
                {heading}
              </StyledHeading>
              <Text color="#363636" lineHeight="1.5">
                {paragraph}
              </Text>
            </div>
          </Flex>
        </CenteredSlide>
        <StyledLazyHero
          imageSrc={image}
          minHeight="100vh"
          isFixed
          style={{ width: "50%", borderLeft: "1px solid #eee" }}
        ></StyledLazyHero>
      </FullHeightSection>
    )
  } else {
    return (
      <FullHeightSection id={id}>
        <StyledLazyHero
          imageSrc={image}
          minHeight="100vh"
          isFixed
          style={{ width: "50%", borderRight: "1px solid #eee" }}
        ></StyledLazyHero>
        <CenteredSlide style={{ borderLeft: "1px solid #eee" }}>
          <Flex width="50%" justifyContent="center">
            <div>
              <Heading
                color="#2F8996"
                fontSize={48}
                fontFamily="abeatbykai"
                my={3}
                sx={{
                  textTransform: "lowercase",
                  fontStyle: "normal",
                  fontWeight: 500,
                  letterSpacing: "1.3px",
                  lineHeight: 1.125,
                }}
              >
                {heading}
              </Heading>
              <Text color="#363636" lineHeight="1.5">
                {paragraph}
              </Text>
            </div>
          </Flex>
        </CenteredSlide>
      </FullHeightSection>
    )
  }
}
export default Page
